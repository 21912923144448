import React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/layout"
// import DynamicPageTemplate from "./dynamic-page"

import {
  Page,
  MajorColumn,
  MinorColumn,
  FlexWrapMobile,
} from "../../components/content-elements"
import GalleryListing from "../../components/gallery_listing"

const AboutPageTemplate = ({ data, location }) => {
  const { page, galleries } = data

  return (
    <Layout location={location}>
      <FlexWrapMobile>
        <MinorColumn><GalleryListing data={galleries} columns={2} /></MinorColumn>
        <MajorColumn>
          <Page page={page} seo={true} />
        </MajorColumn>
      </FlexWrapMobile>
    </Layout>
  )
}


export default AboutPageTemplate

export const pageQuery = graphql`    
    query aboutById($id: String!) {
        ...GalleryListFragment
        page: markdownRemark(id: {eq: $id}) {
            ...GeneralDynamciPageFields
        }
        marketing: markdownRemark(id: {eq: $id}) {
            ...SEOFields
            ...MarketingFields
        }
    }
`